@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
a{
  text-decoration: none !important;
}
body{
  font-family: 'Poppins', sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  background: #fbfafa !important;
}
html {
    --scrollbarBG: rgb(227, 226, 226);
    --thumbBG: #073c7c;
  }
  body::-webkit-scrollbar {
    width: 10px;
  }
  body::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--thumbBG) ;
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);
  }


/*----- All Buttons -----*/
.btn-logout,
.btn-submit{
  background: linear-gradient(145deg, #1267cf, #073c7c);
  padding: 4px 12px;
  border-radius: 10px !important;
  border: 1px solid transparent !important;
  transition: 0.2s  !important;
}
.btn-logout:hover{
  transform: translateX(-10px) !important;
  background: transparent !important;
  color: #073c7c !important;
  border: 1px solid #073c7c !important;
}

.btn-navigate-left,
.btn-navigate-right{
  background: linear-gradient(145deg, #1267cf, #073c7c);
  border: 1px solid transparent !important;
  border-radius: 12px !important;
  transition: 0.3s !important;
}
.btn-navigate-left:hover{
  background: transparent !important;
  transform: translateX(-10px) !important;
  border: 1px solid #073c7c !important;
  color: #073c7c !important;
}
.btn-navigate-right:hover{
  background: transparent !important;
  transform: translateX(10px) !important;
  border: 1px solid #073c7c !important;
  color: #073c7c !important;
}

.title {
  background: linear-gradient(145deg, #1267cf, #073c7c);
  color: #FFFFFF;
  text-align: center;
  border-radius: 15px;
}



/* --- Login CSS ----*/
.loginNew {
  height: 100vh;
}
.loginNew .left {
  background: url('./Assets/SakalLogo.png') center center no-repeat;
  background-size: cover;
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.loginNew h2 {
  background: linear-gradient(180deg, #4c4c4c, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
}


.loginNew .right .card {
  border: 0px;
  border-radius: 15px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  width: 100%;
}
.loginNew .right input {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
}
.loginNew .right .input-group-text {
  /* border: none none none none !important; */
  border-color: transparent transparent #363434 transparent !important;
  border-radius: 0px !important;
  padding: 4px 10px !important;
  background: transparent;
  margin-left: -2px;
}

.loginNew .right input:focus {
  outline: none;
  box-shadow: none;
}

/* footer css */

@media (max-width: 992px) {
  .loginNew .right .card {
    width: 100%;
  }
}

.loginNew .right input {
  border-radius: 12px;
}

.loginNew .right span {
  border-radius: 12px;
}



nav{
  position: fixed;
  width:100%;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 20px;
  z-index: 1000;
 }

 nav h5{
  color: black;
  font-weight: 600;
 }

 nav ul{
display: flex;
list-style: none;
padding: 0px;
margin: 0px;
}

 nav ul li {
  letter-spacing: 1px;
  font-weight: 400;
  padding: 2px 10px;
  transition: 0.2s;
  display: inline-block;
  color: var(--yellow-brand-color);
}

nav ul li:hover{
  transform: translateY(-3px);
  color: white;
 }
  
@media(max-width:992px){
  
.lg-nav{
  display: none;
}

.mobile-nav {
  position: fixed;
  --gap:2rem;
  border-radius: 0px;
  inset:0 0 0 0%;
  z-index: 1000;
  flex-direction: column;
  padding: 20% 0px;
  transform: translateX(100%);
  transition: 0.3s;
  list-style: none;
  margin: 0;
  background: #FFFFFF;
  }
  .mobile-nav a.nav-link{
    border: 1px solid #09114f;
    color: #09114f;
    display: flex;
    align-items: center;
    border-radius: 12px;
    margin-bottom: 15px;
    font-weight: 500;
 }
 
.mobile-nav a.nav-link.active{
  background: linear-gradient(145deg, #1267cf, #073c7c);
  border: 1px solid transparent;
  color: #FFFFFF;
  font-weight: 600;
}

  .mobile-nav[data-visible="true"]{
    transform: translateX(0%);
  }

 .mobile-nav-toggle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 30px;
  cursor: pointer;
  transition: all .5s ease-in-out;

  /* border: 3px solid #fff; */
}
.mobile-nav-toggle .menu-btn__burger {
  width: 35px;
  height: 3px;
  background: #073c7c;
  border-radius: 5px;
  transition: all .5s ease-in-out;
  z-index: 9999; 
}

.mobile-nav-toggle .menu-btn__burger::before,
.mobile-nav-toggle .menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 36px;
  height: 3px;
  background: #073c7c;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}

.mobile-nav-toggle .menu-btn__burger::before {
  transform: translateY(-10px);
}

.mobile-nav-toggle .menu-btn__burger::after {
  transform: translateY(10px);
}

.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.mobile-nav-toggle[aria-expanded="true"] .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
 
}


 /* ------ SideBar-lg CSS for Large or Desktop Devices ------ */
 @media(min-width:992px){

  .lg-nav{
    display: flex;
  }
  .PopupData div{
    display: flex;
    align-items: center;
    font-family: "Poppins", sans-serif !important;
    margin-top: 5px;
  }

  .PopupData div p{
    margin-bottom: 0px;
  }
  .mobile-nav{
    display: none;
  }
  .mobile-nav-toggle{
    display: none;
  }

.sidenav-lg::-webkit-scrollbar {
  width: 10px;
}
.sidenav-lg::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.sidenav-lg::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.sidenav-lg-parent{
  position: relative;
 } 

 .sidenav-lg{
  height: 93vh;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: linear-gradient(145deg, #FFFFFF, #FFFFFF);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  width: 40vh;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  scroll-behavior: smooth;
  --scrollbarBG: rgb(255, 255, 255);
  --thumbBG: #073c7c;
  margin-top: 50px;
 }

.sidenav-lg a.nav-link{
   border: 1px solid #09114f;
   color: #09114f;
   display: flex;
   align-items: center;
   border-radius: 12px;
   margin-bottom: 15px;
   font-weight: 500;
}

/* .sidenav-lg a.nav-link .nav-link-title{
  font-weight: 700;
} */

.sidenav-lg a.nav-link.active{
  background: linear-gradient(145deg, #1267cf, #073c7c);
  border: 1px solid transparent;
  color: #FFFFFF;
  font-weight: 600;
}
 
 .outletDiv{
  position: absolute;
  left: 42vh;
  right: 2vh;
  top: 10vh !important;
 }
 }

 @media(max-width:992px){
  .outletDiv{
   position: absolute;
    left: 0vh;
    right: 0vh;
    top: 10vh;
   }
 }


 /* --- SideBar Accordion CSS for all sizes devices ----*/
 
 .accordion .accrodion-body{
  background: transparent;
}

 .accordion-item .accordion-header {
  background-color: transparent !important;
  border: none !important;
  padding: 0px;

}
.accordion .accordion-button{
  background: #073c7c !important;
  color: white !important;
}
.accordion-button:focus{
  outline: none;
  box-shadow: none;
}
.accordion-item .accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

 .accordion-item .accordion-button:focus:after {
  box-shadow: none;
  outline: white;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
  color: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

 .accordion-item {
  box-shadow: 0 2px 10px 0 rgba(110, 130, 208, .18);
  border: 0px;
}

 .accordion .btn-accordionMenu{
  background: transparent !important;
  border: 1px solid #073c7c;
  color: #073c7c;
  transition: 0.3s;
  border-radius: 12px;
}
.accordion .btn-accordionMenu:hover{
  background: #073c7c !important;
  border: 1px solid #073c7c;
  color: #FFFFFF;
}

.chartCard{
  width: 100% !important;
  height: 400px !important;
}
/* ----   Home CSS -------- */ 

.Home{
 height: 100%;
 justify-content: center;
 align-items: center;
 display: flex;
}

.Home .card{
 background: #09114f;
 border-radius: 12px;
 padding: 15px 20px;
 border-radius: 15px;
 text-align: center;
 display: flex;
 transition: 0.3s;
}

.Home .card img{
  width: 100px; 
  height: 100px;
  align-self: center;
  margin-bottom: 15px;
}

@media(min-width:992px){
  .Home{
    height: 100vh;
   }
  .Home .card:hover{
    cursor: pointer;
    transform: scale(1.09);
  }
}

.Home .card h2{
  color: white;
}
/* --------- Overall Sakal Report CSS ------------ */
.overall .card{
  border: none;
  border-radius: 12px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: #FFFFFF;
  text-align: center;
  justify-content: center;
}

.overall h2{
  font-weight: 600;
}
.overall .card h5{
  font-weight: 700;
}
.overall .card p{
  font-weight: 600;
  font-size: 14px;
  margin: 0px;
}

.overall .actualAndTarget{
  background: url('./Assets/chartBg.png') center center no-repeat;
  background-size: cover;
  border-radius: 0px 0px 12px 12px;
  backdrop-filter: 5px;
}
.overall .actualAndTarget h1,
.overall .actualAndTarget h4,
.overall .actualAndTarget p{
  font-weight: 600;
  color: white;
  margin: 0px;
}

.modal-content {
  border-radius: 12px !important;
  border: 0px !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
